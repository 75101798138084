/* recent posts masonry grid */
.masonry-grid {
	display: grid;
	grid-gap: 1em; /* [1] Add some gap between rows and columns */
	grid-template-columns: repeat( auto-fill, minmax( 200px, 1fr ) ); /* [2] Make columns adjust according to the available viewport */
	grid-auto-rows: 10px; /* [3] Set the height for implicitly-created row track */
}

/* nav styles */
.navbar {
	.navbar-toggler {
		position: relative;
		width: 40px;
		height: 40px;

		div {
			position: absolute;
			transition: .3s;
			background: #343a40;
			border-radius: 2px;

			&:first-of-type {
				top: 25%;
				bottom: 25%;
				width: 10%;
				left: 45%;
			}

			&:last-of-type {
				left: 25%;
				right: 25%;
				height: 10%;
				top: 45%;
			}
		}

		&[aria-expanded="true"] {
			div {
				&:first-of-type,
				&:last-of-type {
					transform: rotate(90deg);
				}
	
				&:last-of-type {
					left: 50%;
					right: 50%;
				}
			}
		}
	}

	.ib-desktop {
		display: inline-block;

		.navbar-nav {
			.nav-item {
				margin: 0 10px;

				.nav-link {
					padding: 0 10px;
				}
			}
		}
	}

	.search-container {
		form {
			.form-element {
				input {
					background: url('/assets/images/lupa.png');
					background-repeat: no-repeat;
					background-position: right 5px center;
					padding: 2px 10px;
					color: #3d3d3d;
					border: 1px solid #c3c3c3;
					transition: all 500ms ease;

					&:focus {
						outline: none;
					}
				}
			}
		}
	}
}

.secondary-nav {
	ul {
		list-style: none;
    display: flex;
		text-align: center;
		padding: 10px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
		margin-bottom: 0;

		li {
			flex: 1;
			position: relative;
			
			a {
				padding: 4px 10px;
				color: #343a40;
				opacity: 0.8;
				transition: all 500ms ease;

				&:hover {
					opacity: 1;
				}

				&.has-dropdown {
					position: relative;
					padding-right: 20px;

					&.opened {
						&::after {
							top: 10px;
							transform: rotate(-135deg);
							-webkit-transform: rotate(-135deg);
						}
					}
	
					&::after {
						content: '';
						border: solid #343a40;
						border-width: 0 3px 3px 0;
						display: inline-block;
						padding: 3px;
						position: absolute;
						right: 0;
						top: 7px;
						transform: rotate(45deg);
						-webkit-transform: rotate(45deg);
						transition: all 500ms ease;
					}
				}
			}	
		}
	}
}

.categories-container {
	background: rgba(255,255,255,.97);
	transition: all 1s ease;

	.categories-list-container {
		list-style: none;
		display: block;
		margin-bottom: 0;
		padding: 10px 40px;
		text-align: left;

		.category-item {
			position: relative;
			text-transform: lowercase;
			display: inline-block;

			a {
				font-size: 16px;
			}

			&:last-of-type {
				&::after {
					content: none;
				}
			}

			&::after {
				content: '|';
				color: #343a40;
				opacity: 0.8;	
			}
		}
	}
}

.recent-posts {
	padding-top: 50px;
	width: 80%;
	margin: auto;
}

/* seccion pregunta */
.pregunta-container {
	background: url('/assets/images/banderas-background.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	padding: 50px;
	margin-bottom: 0;

	.form-container {
		display: flex;
		justify-content: center;

		form {
			.form-element {
				padding: 20px 0;

				input {
					display: block;
					text-align: center;
					min-height: 35px;
					width: 500px;
					font-size: 18px;
					margin: auto;
					padding-left: 8px;
				}

				input[type="submit"] {
					background: #de6362;
					border: 4px solid #bf312f;
					font: 800 20px/25px Open Sans,sans-serif;
					color: #fefced;
					text-shadow: 2px 2px #bf312f;
					padding: 15px;
					text-align: center;
					cursor: pointer;
					-webkit-transition: all .5s ease;
					-o-transition: all .5s ease;
					transition: all .5s ease;

					&:hover {
						background-color: #e54139; 
					}
				}
			}
		}
	}

	.success-message {
		display: none;

		p {
			font-size: 38px;
			color: #fefced;
			text-align: center;
		}
	}
}

.preguntas {
	margin-top: 30px;
	font-size: 14px;

	.list {
		.question {
			padding: 8px 0;
			margin-bottom: 20px;
			border-bottom: 1px solid #c3c3c3;

			.name {
				font-weight: 700;
			}

			p {
				margin-bottom: 5px;
			}
		}
	}
}

/* posts title font size */
.featured-single-post {
	.wrapthumbnail {
		img {
			width: 100%;
		}
	}
}

.listfeaturedtag h2.card-title,
.listrecent h2.card-title {
	a {
		font-size: 1rem;
		text-transform: uppercase;
	}
}

/* share in landing pages */
.share-landing {
	text-align: left;
	margin-top: 10px;

	ul {
		list-style: none;
		margin-bottom: 0;

		li {
			margin-bottom: 0;
			padding: 0 5px;

			a {
				font-size: 20px;
			}
		}
	}
}


/* keep iframe proportions */
video {
	max-width: 100%;
	width: 100%;
}

.video-thumbnail {
	cursor: pointer;
}

.iframe-container {
	width: 100%;
  position: relative;
  padding-bottom: 53%;
  height: 0; 
	overflow: hidden;

	iframe {
		position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	}
}

.article-detail-container {
	.tags {
		ul {
			margin-bottom: 1.5rem;
		}
	}
}

.article-post {
	.iframe-container {
		margin-bottom: 40px;
	}

	.pdf-container {
		margin-bottom: 40px;

		iframe {
			width: 100%;
			height: 800px;
		}
	}
}

/* media tablet devices */
@media screen and (max-width: 991px) {
	.navbar {
		.ib-desktop {
			display: block;

			.navbar-nav {
				text-align: left;
				padding: 0;
				display: block;
	
				.nav-item {
					margin: 0;
					display: inline-block;

					.nav-link {
						margin: 10px 0;
					}
				}
			}
		}

		.search-container {
			margin-bottom: 10px;

			form {
				.form-element {
					input {
						width: 100%;
					}
				}
			}
		}
	}

	.categories-container {
		.categories-list-container {
			padding: 10px 15px;

			.category-item {
				padding: 0 2px;

				a {
					font-size: 13px;
				}
			}
		}
	}

	.recent-posts {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.article-post {	
		.pdf-container {
			iframe {
				width: 100%;
				height: 500px;
			}
		}
	}
}

/* media mobile devices */
@media screen and (max-width: 767px) {
	.secondary-nav {
		padding: 0 15px;

		ul {
			display: block;
			text-align: left;
			margin: auto;

			li {
				margin: 5px 0;
			}
		}
	}

	.categories-container {
		.categories-list-container {
			padding: 10px 0;

			.category-item {
				margin: 0;

				a {
					font-size: 12px;
					padding: 0;
				}
			}
		}
	}

	.mainheading h1.posttitle {
		font-size: 20px;
		line-height: 1.5;
	}

	.main-content {
		padding-top: 30px;
	}

	.recent-posts {
		padding-top: 25px;
	}

  .pregunta-container {
		padding: 20px 0;

		.form-container {
			form {
				.form-element {
					input {
						width: 100%;
						padding-left: 0;
						font-size: 12px;
					}
				}
			}
		}
	}
}
